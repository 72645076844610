import { Button, Dialog, DialogContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useValidatedNonEmptyInput } from "../hooks/validatedInputs";
import ManagedVaultTextInput from "./ManagedVaultInput";
import { AddressBookItemTypes, useAddressBookContext } from "../context/AddressBookContext";
import { useChainId } from "wagmi";

interface AddressBookSearchModalProps {
    open: boolean;
    onClose: () => void;
    addressType: typeof AddressBookItemTypes[number]
    setValue: (value: string) => void;
}

const AddressBookSearchModal = ({ open, onClose, setValue, addressType }: AddressBookSearchModalProps) => {
    const chainId = useChainId();
    const context = useAddressBookContext();
    const searchInput = useValidatedNonEmptyInput();

    const filteredItems = context.addressBook.filter((item) => {
        if (item.chainId !== chainId) {
            return false;
        }
        if(item.type !== addressType) {
            return false;
        }
        if (item.name.toLowerCase().includes(searchInput.value.toLowerCase()) || item.address.toLowerCase().includes(searchInput.value.toLowerCase())) {
            return true;
        }
    })

    return (
        <Dialog PaperProps={{sx: {width: "80vw", maxWidth: "80vw"}}} open={open} onClose={onClose}>
            <DialogContent>
                <ManagedVaultTextInput input={searchInput}>
                    Search by name or address
                </ManagedVaultTextInput>

                {filteredItems.length > 0 ?
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Name
                                    </TableCell>
                                    <TableCell>
                                        Address
                                    </TableCell>
                                    <TableCell>
                                        Date Added
                                    </TableCell>
                                    <TableCell>

                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredItems.map((item) => (
                                    <TableRow key={item.address}>
                                        <TableCell>
                                            {item.name}
                                        </TableCell>
                                        <TableCell>
                                            {item.address}
                                        </TableCell>
                                        <TableCell>
                                            {new Date(item.dateAdded * 1000).toLocaleString()}
                                        </TableCell>
                                        <TableCell>
                                            <Button variant="contained" onClick={() => {setValue(item.address); onClose()} }>
                                                Use
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography>
                        No items found
                    </Typography>
                }
            </DialogContent>
        </Dialog>
    )
}

export default AddressBookSearchModal;