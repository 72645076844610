import { Box, Container, Stack } from "@mui/system"
import { Typography } from "@mui/material";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { Link } from "@tanstack/react-router";
import HeaderLink from "./HeaderLink";

const Header = () => {
    return (
        <Box borderBottom={"1px solid gray"}>
            <Container>
                <Box display={"flex"} alignItems={"center"} height={"64px"} >
                    {/* LEFT growing */}
                    <Box display={"flex"} gap={6} alignContent={"center"} sx={{ flexGrow: 1 }}>
                        <HeaderLink to="/" main>EVK Vault Manager</HeaderLink>

                        <Box display={"flex"} gap={2} justifyContent={"center"}>
                            <HeaderLink to={"/oracle"}>Oracle</HeaderLink>
                            <HeaderLink to={"/vault"}>Vault</HeaderLink>
                            <HeaderLink to={"/configure-irm"}>Configure IRM</HeaderLink>
                            <HeaderLink to={"/address-book"}>Address Book</HeaderLink>
                        </Box>
                    </Box>
                    <Box>
                        <ConnectButton />
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default Header
