import { Typography } from "@mui/material";
import { fontSize } from "@mui/system";
import { Link } from "@tanstack/react-router";

interface HeaderLinkProps {
    children: React.ReactNode;
    to: string;
    main?: boolean;
}

const HeaderLink = ({ children, to, main }: HeaderLinkProps) => {
    return (
        <Typography
            component={Link}
            to={to}
            fontWeight={main ? "bold" : "normal"}
            fontSize={main ? "1.3em" : "1.1em"}
            sx={{
                lineHeight: "2rem",
                color: "inherit",
                textDecoration: "inherit",
                "&:hover": {
                    textDecoration: "underline"
                }
            }}
        >
            {children}
        </Typography>
    )
}

export default HeaderLink