import { Box, Container } from "@mui/material"
import { Stack } from "@mui/system"
import React from "react"
import HeaderLink from "./HeaderLink"

const Footer = () => {
    return (
        <Container>
            <Box display={"flex"} gap={2}>
                <HeaderLink to={"/md/terms-of-service"}>Terms of Service</HeaderLink>
                <HeaderLink to={"/md/privacy-policy"}>Privacy Policy</HeaderLink>
                <HeaderLink to={"/md/risk-disclosures"}>Risk Disclosures</HeaderLink>
            </Box>
        </Container>
    )
}

export default Footer