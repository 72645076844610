import { createContext, useContext, useState } from "react";
import { useValidatedAddressInput, useValidatedNonEmptyInput, useValidatedPositiveNumberInput, voidValidatedInput } from "../hooks/validatedInputs";
import BatchItem from "../types/BatchItem";

const ManageVaultContextDefault = {
    addCollateralModalOpen: false,
    setAddCollateralModalOpen: (open: boolean) => { },
    saveConfirmModalOpen: false,
    setSaveConfirmModalOpen: (open: boolean) => { },
    nameInput: voidValidatedInput,
    symbolInput: voidValidatedInput,
    governorAdminInput: voidValidatedInput,
    feeReceiverInput: voidValidatedInput,
    interestRateModelInput: voidValidatedInput,
    supplyCapInput: voidValidatedInput,
    borrowCapInput: voidValidatedInput,
    interestFeeInput: voidValidatedInput,
    collaterals: [] as CollateralEntry[],
    clearCollaterals: async () => { },
    addCollateral: (collateral: CollateralEntry | CollateralEntry[]) => { },
    setCollaterals: (collaterals: CollateralEntry[]) => { },
    setBorrowLtv: (vaultAddress: `0x${string}`, ltv: number) => { },
    setLiquidationLtv: (vaultAddress: `0x${string}`, ltv: number) => { },
    setLtvs: (vaultAddress: `0x${string}`, liquidationLtv: number, borrowLtv: number) => { },
    setRampDuration: (vaultAddress: `0x${string}`, duration: number) => { },
    batchItems: [] as BatchItem[],
    setBatchItems: (items: BatchItem[]) => { },
    addBatchItem: async (item: BatchItem | BatchItem[]) => { },
    clearBatchItems: async () => { },
    perspectivesToVerify: {} as perspectivesToVerify,
    setVerifyPerspective: (perspective: `0x${string}`, verify: boolean) => { },
    hookTargetInput: voidValidatedInput,
    hookFlags: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] as HookFlags,
    setHookFlags: (flags: HookFlags) => { },
    configFlags: [false, false] as ConfigFlags,
    setConfigFlags: (flags: ConfigFlags) => { },
    maxLiquidationDiscountInput: voidValidatedInput,
    liquidationCoolOffTimeInput: voidValidatedInput,
    addToRewardStreamModalOpen: false,
    setAddToRewardStreamModalOpen: (open: boolean) => { },
}

type ManageVaultContextType = typeof ManageVaultContextDefault;

const ManageVaultContext = createContext<ManageVaultContextType>(ManageVaultContextDefault);

interface ManageVaultContextProviderProps {
    children: React.ReactNode
}

// 16 flags
export type HookFlags = [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean]
// 2 flags 
export type ConfigFlags = [boolean, boolean]

interface perspectivesToVerify {
    [key: `0x${string}`]: boolean;
}


export interface CollateralEntry {
    vaultAddress: `0x${string}`,
    liquidationLtv: number,
    borrowLtv: number,
    rampDuration: number,
    prevLiquidationLtv?: number,
    prevBorrowLtv?: number,
}

const ManageVaultContextProvider = ({ children }: ManageVaultContextProviderProps) => {

    const [collaterals, setCollaterals] = useState<CollateralEntry[]>([]);
    const [batchItems, setBatchItems] = useState<BatchItem[]>([]);
    const [perspectivesToVerify, setPerspectivesToVerify] = useState<perspectivesToVerify>({});
    const [hookFlags, setHookFlags] = useState<HookFlags>(ManageVaultContextDefault.hookFlags);
    const [configFlags, setConfigFlags] = useState<ConfigFlags>(ManageVaultContextDefault.configFlags);

    const clearCollaterals = async () => {
        await setCollaterals([]);
    }

    const addCollateral = (newCollaterals: CollateralEntry | CollateralEntry[]) => {
        const newCollateralsArray = Array.isArray(newCollaterals) ? newCollaterals : [newCollaterals];
        const filteredNewCollaterals = newCollateralsArray.filter(c => !collaterals.some(c2 => c2.vaultAddress === c.vaultAddress));
        setCollaterals(collaterals.concat(filteredNewCollaterals));
    }

    const addBatchItem = async (items: BatchItem | BatchItem[]) => {
        await setBatchItems(batchItems.concat(items));
    }

    const clearBatchItems = async () => {
        await setBatchItems([]);
    }

    const setVerifyPerspective = (perspective: `0x${string}`, verify: boolean) => {
        setPerspectivesToVerify({
            ...perspectivesToVerify,
            [perspective]: verify
        });
    }

    const setLiquidationLtv = (vaultAddress: `0x${string}`, ltv: number) => {
        const newCollaterals = collaterals.map(c => {
            if (c.vaultAddress === vaultAddress) {
                return {
                    ...c,
                    liquidationLtv: ltv
                }
            }
            return c;
        });
        setCollaterals(newCollaterals);
    }

    const setBorrowLtv = (vaultAddress: `0x${string}`, ltv: number) => {
        const newCollaterals = collaterals.map(c => {
            if (c.vaultAddress === vaultAddress) {
                return {
                    ...c,
                    borrowLtv: ltv
                }
            }
            return c;
        });
        setCollaterals(newCollaterals);
    }

    const setLtvs = (vaultAddress: `0x${string}`, liquidationLtv: number, borrowLtv: number) => {
        const newCollaterals = collaterals.map(c => {
            if (c.vaultAddress === vaultAddress) {
                return {
                    ...c,
                    liquidationLtv,
                    borrowLtv
                }
            }
            return c;
        });
        setCollaterals(newCollaterals);
    }

    const setRampDuration = (vaultAddress: `0x${string}`, duration: number) => {
        const newCollaterals = collaterals.map(c => {
            if (c.vaultAddress === vaultAddress) {
                return {
                    ...c,
                    rampDuration: duration
                }
            }
            return c;
        });
        setCollaterals(newCollaterals);
    }

    const [addCollateralModalOpen, setAddCollateralModalOpen] = useState(false);
    const [saveConfirmModalOpen, setSaveConfirmModalOpen] = useState(false);
    const nameInput = useValidatedNonEmptyInput();
    const symbolInput = useValidatedNonEmptyInput();
    const governorAdminInput = useValidatedAddressInput();
    const feeReceiverInput = useValidatedAddressInput();
    const interestRateModelInput = useValidatedAddressInput();
    const supplyCapInput = useValidatedPositiveNumberInput();
    const borrowCapInput = useValidatedPositiveNumberInput();
    // Min and max should be enforced by the input component
    const interestFeeInput = useValidatedPositiveNumberInput();
    const hookTargetInput = useValidatedAddressInput();
    const maxLiquidationDiscountInput = useValidatedPositiveNumberInput();
    const liquidationCoolOffTimeInput = useValidatedPositiveNumberInput();
    const [addToRewardStreamModalOpen, setAddToRewardStreamModalOpen] = useState(false);

    return (
        <ManageVaultContext.Provider value={{
            addCollateralModalOpen,
            setAddCollateralModalOpen,
            saveConfirmModalOpen,
            setSaveConfirmModalOpen,
            nameInput,
            symbolInput,
            governorAdminInput,
            feeReceiverInput,
            interestRateModelInput,
            supplyCapInput,
            borrowCapInput,
            interestFeeInput,
            collaterals,
            clearCollaterals,
            addCollateral,
            setCollaterals,
            setBorrowLtv,
            setLiquidationLtv,
            setLtvs,
            setRampDuration,
            batchItems,
            setBatchItems,
            addBatchItem,
            clearBatchItems,
            perspectivesToVerify,
            setVerifyPerspective,
            hookTargetInput,
            hookFlags,
            setHookFlags,
            maxLiquidationDiscountInput,
            configFlags,
            setConfigFlags,
            liquidationCoolOffTimeInput,
            addToRewardStreamModalOpen,
            setAddToRewardStreamModalOpen
        }}>
            {children}
        </ManageVaultContext.Provider>
    )

}

const useManageVaultContext = () => {
    return useContext(ManageVaultContext);
}

export { 
    ManageVaultContextProvider,
    ManageVaultContext,
    useManageVaultContext
};