import { useState, useEffect, Dispatch, SetStateAction } from 'react';

const useLocalStorageState = <T,>(key: string, defaultValue: T): [T, Dispatch<SetStateAction<T>>] => {
  // Retrieve initial state from localStorage if it exists, otherwise use defaultValue
  const [state, setState] = useState<T>(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  });

  // Listen for changes to the key in localStorage
  useEffect(() => {
    const handleStorageChange = () => {
      const storedValue = localStorage.getItem(key);
      setState(storedValue ? JSON.parse(storedValue) : defaultValue);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key, defaultValue]);

  // Use useEffect to update localStorage whenever the state changes
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};

export default useLocalStorageState;