import { Box, FormControl } from "@mui/material"

interface ManagedVaultFormControlProps {
    error?: boolean,
    children: React.ReactNode
    fullWidth?: boolean
}

const ManagedVaultFormControl = ({ children, error, fullWidth = true }: ManagedVaultFormControlProps) => {
    return (
        <Box mb={3}>
            <FormControl error={error} fullWidth={fullWidth}>
                {children}
            </FormControl>
        </Box>
    )
}

export default ManagedVaultFormControl;