import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { Link, useParams, useRouterState } from "@tanstack/react-router";
import React, { useState } from "react";
import useLocalStorageState from "../hooks/useLocalStorageState";
import { useSnackbar } from "notistack";
import { policiesVersion } from "../config/policies";

const TosModal = () => {
    const [open, setOpen] = useLocalStorageState(`tos-modal${policiesVersion}`, true);
    const routerState = useRouterState();
    const snackbar = useSnackbar();

    // If path begings with /md/ then we know we are on a markdown page so we skip the modal
    if (routerState.location.pathname.startsWith("/md/")) {
        return null;
    }

    return (
        <Dialog open={open} onClose={() => { }}>
            <DialogTitle>Acknowledge Terms</DialogTitle>
            <DialogContent>

                <Stack spacing={3}>
                    <Typography>
                        By accessing or using Euler's products and services, I agree to the <Link to="/md/terms-of-service">Terms of Use</Link>, <Link to="/md/privacy-policy">Privacy Policy</Link>, and <Link to="/md/risk-disclosures">Risk Disclosures</Link>. I further represent and warrant:
                    </Typography>
                    <Typography>
                        I am not a resident of, located in, or incorporated in any Restricted Jurisdiction. I will not access this site or use our products or services while in any restricted locations, nor use a VPN to mask my location.
                    </Typography>
                    <Typography>
                        I am permitted to access this site and use Euler software under the laws of my jurisdiction.
                    </Typography>
                    <Typography>
                        I am not a Sanctioned Person (as defined in the ToU) nor acting on behalf of one.
                    </Typography>
                    <Typography>
                        The App, Protocol, and related software are experimental and may result in complete loss of funds. The company and its affiliates do not custody or control user assets or transactions; liquidations are performed by the protocol or its users.
                    </Typography>
                    <Typography>
                        I understand the risks of decentralised finance and engaging with blockchain and other web3 software and services, including technical, operational, market, liquidity, and legal risks.
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color="error" onClick={() => snackbar.enqueueSnackbar("Must agree to use this application.", {variant: "error"})}>Reject</Button>
                <Button onClick={() => {setOpen(false)}} variant="contained">Accept</Button>
            </DialogActions>
        </Dialog>
    )
}

export default TosModal;