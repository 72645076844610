import { Box, Button, Dialog, DialogActions, DialogContent, FormLabel, MenuItem, Select } from "@mui/material";
import { AddressBookItem, AddressBookItemTypes, AddressBookItemTypesHumanReadable, useAddressBookContext } from "../context/AddressBookContext";
import ManagedVaultTextInput from "./ManagedVaultInput";
import { isAddress } from "viem";
import { useChainId } from "wagmi";

const AddToAddressBookModal = () => {
    const context = useAddressBookContext();
    const chainId = useChainId();

    const onClose = () => {
        context.setAddToAddressModalOpen(false)

        // Reset inputs
        context.addressInput.setValue("");
        context.nameInput.setValue("");
        context.setEditingItem(null);
    }

    const onSave = () => {
        // Validate inputs
        if (!(isAddress(context.addressInput.value) && context.nameInput.value.length > 0)) {
            return;
        }

        // If editing an item, remove the old item and add the new one
        if (context.editingItem !== null) {
            context.setAddressBook((prev) => {
                const next: AddressBookItem[] = [...prev];

                next[context.editingItem] = {
                    address: context.addressInput.value,
                    name: context.nameInput.value,
                    type: context.addressBookItemTypeSelected,
                    dateAdded: next[context.editingItem].dateAdded,
                    chainId: chainId
                }

                return next;
            })
        } else {
            context.addToAddressBook({
                address: context.addressInput.value,
                name: context.nameInput.value,
                type: context.addressBookItemTypeSelected,
                dateAdded: Math.floor(Date.now() / 1000),
                chainId: chainId
            })
        }


        onClose();
    }

    return (
        <Dialog PaperProps={{ sx: { minWidth: "40vw" } }} open={context.addressBookModalOpen} onClose={onClose}>
            <DialogContent>
                <ManagedVaultTextInput input={context.addressInput}>Address</ManagedVaultTextInput>
                <ManagedVaultTextInput input={context.nameInput}>Name</ManagedVaultTextInput>
                <Box>
                    <FormLabel sx={{ display: "block", width: "100%" }}>Item type</FormLabel>
                    <Select fullWidth value={context.addressBookItemTypeSelected} onChange={(e) => context.setAddressBookItemTypeSelected(e.target.value as any)}>
                        {
                            AddressBookItemTypes.map((type) => (
                                <MenuItem key={type} value={type}>
                                    {AddressBookItemTypesHumanReadable[type]}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Cancel
                </Button>
                <Button onClick={onSave} variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddToAddressBookModal;