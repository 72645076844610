import { createContext, useContext, useState } from "react";
import useLocalStorageState from "../hooks/useLocalStorageState";
import { useValidatedAddressInput, useValidatedNonEmptyInput, voidValidatedInput } from "../hooks/validatedInputs";
import { useChainId } from "wagmi";

export const AddressBookItemTypes = ["token", "oracleRouter", "irm", "vault"] as const;

export interface AddressBookItem {
    address: `0x${string}`,
    name: string,
    chainId: number,
    dateAdded: number,
    data?: any,
    id?: number,
    type: typeof AddressBookItemTypes[number]
}

export const AddressBookItemTypesHumanReadable = {
    token: "Token",
    oracleRouter: "Oracle Router",
    irm: "Interest Rate Model",
    vault: "Vault"
}

const AddressBookContextDefault = {
    addressBook: [] as AddressBookItem[],
    setAddressBook: (addressBook: AddressBookItem[]) => { },
    addToAddressBook: (item: AddressBookItem | AddressBookItem[]) => { },
    reorder: (from: number, to: number) => { },
    remove: (address: `0x${string}`) => { },
    addressBookModalOpen: false,
    setAddToAddressModalOpen: (open: boolean) => { },
    addressInput: voidValidatedInput,
    nameInput: voidValidatedInput,
    addressBookItemTypeSelected: "token" as typeof AddressBookItemTypes[number],
    setAddressBookItemTypeSelected: (type: typeof AddressBookItemTypes[number]) => { },
    editingItem: null as number | null,
    setEditingItem: (index: number | null) => { }
}

type AddressBookContextType = typeof AddressBookContextDefault;

const AddressBookContext = createContext<AddressBookContextType>(AddressBookContextDefault);

interface AddressBookContextProviderProps {
    children: React.ReactNode
}

const AddressBookContextProvider = ({ children }: AddressBookContextProviderProps) => {

    const [addressBook, setAddressBook] = useLocalStorageState<AddressBookItem[]>("addressBook", [])
    const [addressBookModalOpen, setAddToAddressModalOpen] = useState(false)
    const addressInput = useValidatedAddressInput();
    const nameInput = useValidatedNonEmptyInput();
    const [addressBookItemTypeSelected, setAddressBookItemTypeSelected] = useState<typeof AddressBookItemTypes[number]>("token");
    const [editingItem, setEditingItem] = useState<number | null>(null);
    const currentChainId = useChainId();

    const addToAddressBook = (item: AddressBookItem | AddressBookItem[]) => {
        setAddressBook((prev) => {
            if (Array.isArray(item)) {
                return [...prev, ...item]
            }
            return [...prev, item]
        })
    }

    const reorder = (from: number, to: number) => {
        setAddressBook((prev) => {
            const copy = [...prev]
            const [removed] = copy.splice(from, 1)
            copy.splice(to, 0, removed)
            return copy
        })
    }

    const remove = (address: `0x${string}`, chainId?: number) => {
        if (chainId === undefined) {
            chainId = currentChainId;
        }

        setAddressBook((prev) => {
            return prev.filter((item) => !(item.address === address && item.chainId === chainId))
        })
    }

    return (
        <AddressBookContext.Provider value={{
            addressBook,
            setAddressBook,
            addToAddressBook,
            reorder,
            remove,
            addressBookModalOpen,
            setAddToAddressModalOpen,
            addressInput,
            nameInput,
            addressBookItemTypeSelected,
            setAddressBookItemTypeSelected,
            editingItem,
            setEditingItem
        }}>
            {children}
        </AddressBookContext.Provider>
    )
}

const useAddressBookContext = () => {
    return useContext(AddressBookContext);
}

export { 
    AddressBookContextProvider,
    useAddressBookContext
}