import { createContext, useContext, useState } from "react";
import { useValidatedAddressInput, voidValidatedInput } from "../hooks/validatedInputs";
import { QueryKey } from "@tanstack/react-query";

const ManageOracleRouterContextDefault = {
    governorInput: voidValidatedInput,
    addResolvedVaultModalOpen: false,
    setAddResolvedVaultModalOpen: (open: boolean) => { },
    addOracleAdapterModalOpen: false,
    setAddOracleAdapterModalOpen: (open: boolean) => { },
    addAdapterToken0Input: voidValidatedInput,
    addAdapterToken1Input: voidValidatedInput,
    addAdapterAdapterInput: voidValidatedInput,
    routerAddress: "",
    setRouterAddress: (address: string) => { },
    queryKeyToInvalidate: undefined as QueryKey | undefined,
    setQueryKeyToInvalidate: (queryKey: QueryKey | undefined) => { }
}

type ManageOracleRouterContextType = typeof ManageOracleRouterContextDefault;

const ManageOracleRouterContext = createContext<ManageOracleRouterContextType>(ManageOracleRouterContextDefault);

interface ManageOracleRouterContextProviderProps {
    children: React.ReactNode
}

const ManageOracleRouterContextProvider = ({ children }: ManageOracleRouterContextProviderProps) => {
    const governorInput = useValidatedAddressInput();
    const [addResolvedVaultModalOpen, setAddResolvedVaultModalOpen] = useState(false);
    const [addOracleAdapterModalOpen, setAddOracleAdapterModalOpen] = useState(false);
    const [routerAddress, setRouterAddress] = useState("");
    const [queryKeyToInvalidate, setQueryKeyToInvalidate] = useState<QueryKey | undefined>(undefined);
    
    const addAdapterToken0Input = useValidatedAddressInput();
    const addAdapterToken1Input = useValidatedAddressInput();
    const addAdapterAdapterInput = useValidatedAddressInput();

    return (
        <ManageOracleRouterContext.Provider value={{
            governorInput,
            addResolvedVaultModalOpen,
            setAddResolvedVaultModalOpen,
            addOracleAdapterModalOpen,
            setAddOracleAdapterModalOpen,
            addAdapterToken0Input,
            addAdapterToken1Input,
            addAdapterAdapterInput,
            routerAddress,
            setRouterAddress,
            queryKeyToInvalidate,
            setQueryKeyToInvalidate
        }}>
            {children}
        </ManageOracleRouterContext.Provider>
    )
}

const useManageOracleRouterContext = () => {
    return useContext(ManageOracleRouterContext);
}

export { 
    ManageOracleRouterContextProvider,
    useManageOracleRouterContext
}