import { Button, FormLabel, Input } from "@mui/material";
import { ValidatedInput } from "../hooks/validatedInputs";
import ManagedVaultFormControl from "./ManageVaultFormControl";
import { AddressBookItemTypes } from "../context/AddressBookContext";
import { useState } from "react";
import { Add, Search } from "@mui/icons-material";
import AddressBookSearchModal from "./AddressBookSearchModal";
import { zeroAddress } from "viem";

interface ManagedVaultTextInputProps {
    input: ValidatedInput
    type?: "text" | "number"
    children?: React.ReactNode
    disabled?: boolean
    endAdornment?: React.ReactNode
    addressType?: typeof AddressBookItemTypes[number]
    revokable?: boolean
}

const ManagedVaultTextInput = ({ children, input, type = "text", disabled, endAdornment, addressType, revokable }: ManagedVaultTextInputProps) => {

    const [searchModalOpen, setSearchModalOpen] = useState(false)

    const revoke = () => {
        input.setValue(zeroAddress);
    }

    let adornment: React.ReactNode;
    if(endAdornment) {
        adornment = endAdornment;
    } else if(addressType) {
        adornment = <Search onClick={() => setSearchModalOpen(true)} />
    } else if(revokable && input.value !== zeroAddress) {
        adornment = <Button onClick={revoke}>Revoke</Button>
    }


    return (
        <>
            <ManagedVaultFormControl error={input.isError()}>
                {children && <FormLabel>{children}</FormLabel>}
                <Input disabled={disabled} type={type} value={input.value} onChange={input.onChange} endAdornment={adornment} />
            </ManagedVaultFormControl>
            { addressType && 
                <AddressBookSearchModal
                    open={searchModalOpen}
                    onClose={() => setSearchModalOpen(false)}
                    addressType={addressType}
                    setValue={input.setValue}
                />
            }
        </>
    )
}

export default ManagedVaultTextInput;