type status = "empty" | "invalid" | "valid";

import { useState } from "react";
import { isAddress } from "viem";

export interface ValidatedInput {
    status: status,
    value: string,
    prevValue?: string | undefined
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    setValue: (value: string) => void,
    setValueAndPrevValue: (value: string) => void,
    isError: () => boolean,
    validator: (value: string) => status
    setStatus: (status: status) => void
}

export const validateAddressInput = (address: string): status => {
    if (address === "") {
        return "empty";
    }
    if (isAddress(address)) {
        return "valid";
    }
    return "invalid";
        
}

export const validatePositiveNumberInput = (value: string): status => {
    if (value === "") {
        return "empty";
    }
    if (parseFloat(value) > 0) {
        return "valid";
    }
    return "invalid";
}

export const validateNonEmptyInput = (value: string): status => {
    if (value === "") {
        return "empty";
    }
    return "valid";

}

// This hook is a generic hook that can be used to create a validated input hook from a validator function.
export const useValidatedInputFromValidator = (validator: (value: string) => status) => {
    const [status, setStatus] = useState<status>("empty");
    const [value, setValue] = useState<string>("");
    const [prevValue, setPrevValue] = useState<string | undefined>(undefined);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        setStatus(validator(e.target.value));
    }

    const setValueAndValidate = (value: string) => {
        setValue(value);
        setStatus(validator(value));
    }

    const setValueAndPrevValue = (value: string) => {
        setValue(value);
        setStatus(validator(value));
        setPrevValue(value);
    }

    const isError = () => status === "invalid";
    
    return {
        status,
        value,
        setValue: setValueAndValidate,
        prevValue,
        setValueAndPrevValue,
        onChange,
        isError,
        validator,
        setStatus
    }
}

export const useValidatedPositiveNumberInput = (): ValidatedInput => {
    return useValidatedInputFromValidator(validatePositiveNumberInput);
}

export const useValidatedAddressInput = (): ValidatedInput => {
    return useValidatedInputFromValidator(validateAddressInput);
}

export const useValidatedNonEmptyInput = (): ValidatedInput => {
    return useValidatedInputFromValidator(validateNonEmptyInput);
}

// Used as default value in contexts
export const voidValidatedInput: ValidatedInput = {
    status: "empty",
    value: "",
    setValue: () => {},
    onChange: () => {},
    isError: () => false,
    validator: () => "empty",
    setStatus: () => {}
}
